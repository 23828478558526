<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider vid="org_id" name="Organization" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="project_info.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="!$store.state.Auth.activeRoleId"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office Type" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="project_info.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="!$store.state.Auth.activeRoleId"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                </template>
                                <v-select name="region_office_id"
                                  v-model="project_info.office_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= officeList
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                  :disabled="!$store.state.Auth.activeRoleId"
                                />
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider vid="circular_id" name="Circular" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('research_manage.circular_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="project_info.circular_id"
                                    :options="circularList"
                                    id="circular_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider vid="thematic_area_id" name="Thematic Area" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="thematic_area_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('research_manage.thematic_area_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="project_info.thematic_area_id"
                                    :options="thematicAreaList"
                                    id="thematic_area_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="id ? true : false"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider vid="project_setup_detail_id" name="Project" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="project_setup_detail_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('research_manage.project_name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="project_info.project_setup_detail_id"
                                  :options="projectListDrop"
                                  id="project_setup_detail_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="id ? true : false"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        </b-col>
                        <b-col lg="6" sm="12" v-if="project_info.circular_id">
                          {{ $t('research_manage.memo_no') }} : {{ memo_no
                          }}
                        </b-col>
                        <b-col lg="6" sm="12" v-if="project_info.circular_id">
                          {{ $t('research_manage.submission_deadline') }} : {{ submission_deadline | dateFormat}}
                        </b-col>
                    </b-row>
                  </b-form>
              </ValidationObserver>
              <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off" v-if="project_info.org_id">
                  <b-row class="p-4">
                    <b-col style="background-color:green;" lg="12" class="p-2">
                      <template>
                        <h6 style="" class="text-sm-center text-light">{{ $t('research_manage.project_info') }}</h6>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Project Name (En)" vid="sub_project_name_en" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="sub_project_name_en"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.sub_project_title') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="sub_project_name_en"
                            v-model="detailsFormData.sub_project_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Project Name (Bn)" vid="sub_project_name_bn" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="sub_project_name_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.sub_project_title') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="sub_project_name_bn"
                            v-model="detailsFormData.sub_project_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Objectives (En)" vid="objective_en" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="objective_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('research_manage.objectives') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                          </template>
                          <b-textarea
                            id="objective_en"
                            v-model="detailsFormData.objective_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Objectives (Bn)" vid="objective_bn" rules="required">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="objective_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.objectives') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-textarea
                            id="objective_bn"
                            v-model="detailsFormData.objective_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Designation" vid="pi_designation_id" rules="required|min_value:1">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="pi_designation_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.designation') }} <span class="text-danger">*</span>
                          </template>
                          <!-- <b-form-input
                            id="pi_designation_id"
                            v-model="detailsFormData.pi_designation_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input> -->
                          <b-form-select
                            plain
                            v-model="detailsFormData.pi_designation_id"
                            :options="designationList"
                            id="pi_designation_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Principal Investigator" vid="pi_officer_id" rules="required|min_value:1">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="pi_officer_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('external_research.principal_investigator') }} <span class="text-danger">*</span>
                          </template>
                          <b-overlay :show="officerLoad">
                          <b-form-select
                            plain
                            v-model="detailsFormData.pi_officer_id"
                            :options="officerList.filter(item => item.designation_id === detailsFormData.pi_designation_id)"
                            id="pi_officer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          </b-overlay>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Copy Designation" vid="co_pi_designation_id" rules="required|min_value:1">
                        <b-form-group
                          label-cols-sm="5"
                          label-for="co_pi_designation_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('research_manage.co_py') }} {{ $t('research_manage.designation') }} <span class="text-danger">*</span>
                          </template>
                          <v-select name="co_py"
                            v-model="detailsFormData.co_pi_designation_id"
                            label="text"
                            :reduce="item => item.value"
                             :options="designationList"
                            :placeholder="$t('globalTrans.select')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :filter-by="myFilter"
                            multiple
                            @input="changeProductInfo()"
                          />
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Co-Py" vid="pi_co_py" rules="required">
                        <b-form-group
                            label-cols-sm="5"
                            label-for="pi_co_py"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('research_manage.co_py') }} <span class="text-danger">*</span>
                            </template>
                            <v-select name="co_py"
                                v-model="detailsFormData.co_py"
                                label="text"
                                :reduce="item => item.value"
                                :options="coPyUserList"
                                :placeholder="$t('globalTrans.select')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :filter-by="myFilter"
                                multiple
                            />
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                        <div class="text-right mb-2">
                            <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                            <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                        </div>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
              <br/>
              <b-overlay :show="officerLoad">
                <b-table-simple bordered v-if="officerList.length">
                  <thead>
                    <tr style="background-color:green;">
                      <th style="width:5%" class="text-light">{{ $t('globalTrans.sl_no') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('research_manage.project_name') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('research_manage.sub_project_title') }}</th>
                      <th style="width:15%" class="text-light">{{ $t('research_manage.objectives') }}</th>
                      <th style="width:15%" class="text-light">{{ $t('research_manage.designation') }}</th>
                      <th style="width:15%" class="text-light">{{ $t('external_research.principal_investigator') }}</th>
                      <th style="width:16%" class="text-light">{{ $t('research_manage.co_py') }} {{ $t('research_manage.designation') }}</th>
                      <th style="width:17%" class="text-light">{{ $t('research_manage.co_py') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('globalTrans.action') }}</th>
                    </tr>
                  </thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in project_info.project_info_details" :key="index">
                        <b-td>
                            {{ $n(index + 1) }}
                        </b-td>
                        <b-td v-if="projectListDropArray">
                          {{ getColumnName(projectListDropArray, item.project_setup_detail_id) }}
                        </b-td>
                        <b-td>
                          {{ currentLocale == 'bn' ? item.sub_project_name_bn : item.sub_project_name_en }}
                        </b-td>
                        <b-td>
                          {{ currentLocale == 'bn' ? item.objective_bn : item.objective_en }}
                        </b-td>
                        <b-td>
                          {{ getDesignation(item.pi_designation_id) }}
                        </b-td>
                        <b-td>
                            {{ getOfficer(item.pi_officer_id) }}
                        </b-td>
                        <b-td>
                          {{ getMultiDesigName(item.co_pi_designation_id) }}
                        </b-td>
                        <b-td>
                          {{ getCoPyName(item.co_py) }}
                        </b-td>
                        <b-td>
                          <span class="d-flex text-center">
                            <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </span>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="project_info.project_info_details.length <= 0">
                        <td colspan="12" class="text-center">
                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                        </td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-overlay>
              <b-row class="text-right">
                <b-col>
                  <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                  <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>-->
                  <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                  <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../../config/api_config'
import { projectSetupSubStore, projectSetupSubUpdate, circularList, projectList } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id', 'assignDesignationList'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        options: [
          { value: 1, text: 'Coordinate Research' },
          { value: 2, text: 'Plain Research' }
        ],
        editIndex: '',
        memo_no: '',
        submission_deadline: '',
        flag_status: 1,
        project_info: {
          org_id: this.$store.state.Auth.activeRoleId !== 1 ? this.$store.state.Auth.authUser.org_id : 0,
          circular_id: 0,
          thematic_area_id: 0,
          project_setup_detail_id: 0,
          office_type_id: 0,
          office_: 0,
          memo_no: 0,
          submission_deadline: 0,
          project_info_details: [
          ]
        },
        detailsFormData: {
            sub_project_name_en: '',
            sub_project_name_bn: '',
            objective_en: '',
            objective_bn: '',
            pi_designation_id: 0,
            co_pi_designation_id: [],
            co_py: [],
            pi_officer_id: 0
        },
        circularList: [],
        thematicAreaOffice: [],
        designationList: [],
        thematicAreaList: [],
        officerList: [],
        officeTypeList: [],
        officeList: [],
        coPyUserList: [],
        allOfficerList: [],
        circularData: [],
        projectListDrop: [],
        projectListDropArray: [],
        myFilter: (option, text, search) => {
          const temp = search.toLowerCase()
          return option.text_en.toLowerCase().indexOf(temp) > -1 ||
          option.text_bn.toLowerCase().indexOf(temp) > -1
        }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(project => {
        return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
      })
    },
    yearList: function () {
      return this.$store.state.commonObj.yearList
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getProjectData()
      tmp.project_setup_detail_id = tmp.id
      this.project_info = tmp
    } else {
       this.project_info = Object.assign({}, this.project_info, {
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.project_info.org_id = this.$store.state.Auth.authUser.org_id
        this.project_info.office_type_id = this.$store.state.Auth.authUser.office_type_id
        this.project_info.office_id = this.$store.state.Auth.authUser.office_id
      }
    }
    this.getOfficerList()
  },
  mounted () {
    core.index()
  },
  watch: {
    'project_info.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
          this.officeTypeList = this.getOfficeTypeList(newValue)
      } else {
          this.officeTypeList = []
      }
    },
    'project_info.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'project_info.office_id': function (newValue, oldValue) {
        this.getOfficerList()
        if (newValue !== oldValue) {
          this.circularList = this.getCircularList(newValue)
          this.designationList = this.getDesignationList(newValue)
        } else {
          this.circularList = []
          this.thematicAreaList = []
        }
    },
    'project_info.circular_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getProjectList()
        if (!this.id) {
          this.getCircularMemo(newValue)
        }
      }
    },
    'project_info.thematic_area_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getProjectListAta(newValue)
      }
    }
  },
  methods: {
    getMultiDesigName (data) {
      const dataName = []
      data.forEach((item, key) => {
        const itThere = this.$store.state.commonObj.designationList.find(obj => obj.value === parseInt(item))
        dataName.push(this.$i18n.locale === 'bn' ? itThere.text_bn : itThere.text_en)
      })
      return dataName.toString()
    },
    changeProductInfo () {
      const officerList = []
      if (this.detailsFormData.co_pi_designation_id) {
        if (this.detailsFormData.co_pi_designation_id.length > 0) {
          this.detailsFormData.co_pi_designation_id.forEach((item, key) => {
            const coPyUserLists = this.officerList.filter(item1 => item1.designation_id === item)
            coPyUserLists.forEach((item1, key) => {
              officerList.push(item1)
            })
          })
          this.coPyUserList = officerList
          const searchData = []
          if (this.coPyUserList.length > 0) {
            this.coPyUserList.forEach((item, key) => {
              if (this.detailsFormData.co_py.length > 0) {
                this.detailsFormData.co_py.map((item1) => {
                  if (item1 === item.value) {
                    searchData.push(item1)
                  }
                })
              }
            })
          }
          this.detailsFormData.co_py = searchData
        } else {
          this.detailsFormData.co_py = []
        }
      }
    },
    coPyuser () {
      const coPyUserList = []
      this.detailsFormData.co_pi_designation_id.forEach((item, key) => {
        const coPyUserLists = this.officerList.filter(item1 => item1.designation_id === parseInt(item))
        coPyUserLists.forEach((item1, key) => {
          coPyUserList.push(item1)
        })
      })
      this.coPyUserList = coPyUserList
    },
    getDesignationList (officeId) {
      const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId)
      const designationList = assignDesignationList.map(item => {
        const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
        return designation
      })
      return designationList
    },
    getProjectListAta (thermArea = null) {
      const projectListDropArrays = this.projectListDropArray.filter(projectListDropArray => projectListDropArray.thematic_area_id === thermArea)
      this.projectListDrop = projectListDropArrays.map(item => {
        return Object.assign({ value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en, text_en: item.text_en, text_bn: item.text_bn, thematic_area_id: item.thematic_area_id })
      })
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
      return officeTypeList
    },
    getOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
        return ''
      }
    },
    getCoPyName (data) {
      const dataName = []
      data.forEach((item, key) => {
        const itThere = this.getOfficer(item)
        dataName.push(itThere)
      })
      return dataName.toString()
    },
    getDesignation (id) {
        const Obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
        if (Obj) {
          if (this.$i18n.locale === 'en') {
            return Obj.text_en
          } else {
            return Obj.text_bn
          }
        } else {
          return ''
        }
    },
    getOfficer (id) {
      const Obj = this.officerList.find(item => item.value === parseInt(id))
      if (Obj) {
        if (this.$i18n.locale === 'en') {
            return Obj.text_en
        } else {
            return Obj.text_bn
        }
      } else {
        return ''
      }
    },
    cancelItem () {
        this.detailsFormData = {}
        this.editIndex = ''
        this.$refs.form1.reset()
    },
    editItem (item, index) {
        this.detailsFormData = Object.assign({}, item)
        this.editIndex = index
    },
    deleteItem (index) {
        this.project_info.project_info_details.splice(index, 1)
    },
    async addItem () {
      let result = []
      if (this.editIndex !== '') {
          const editList = [...this.project_info.project_info_details]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
      } else {
          result = this.project_info.project_info_details
      }
      const projectName = this.detailsFormData.sub_project_name_en
      const newData = result.find(item => item.sub_project_name_en === projectName)
      if (typeof newData === 'undefined') {
            if (this.editIndex !== '') {
                this.project_info.project_info_details[parseInt(this.editIndex)].sub_project_name_en = this.detailsFormData.sub_project_name_en
                this.project_info.project_info_details[parseInt(this.editIndex)].sub_project_name_bn = this.detailsFormData.sub_project_name_bn
                this.project_info.project_info_details[parseInt(this.editIndex)].objective_en = this.detailsFormData.objective_en
                this.project_info.project_info_details[parseInt(this.editIndex)].objective_bn = this.detailsFormData.objective_bn
                this.project_info.project_info_details[parseInt(this.editIndex)].pi_designation_id = this.detailsFormData.pi_designation_id
                this.project_info.project_info_details[parseInt(this.editIndex)].pi_officer_id = this.detailsFormData.pi_officer_id
                this.project_info.project_info_details[parseInt(this.editIndex)].co_py = this.detailsFormData.co_py
                this.project_info.project_info_details[parseInt(this.editIndex)].project_setup_detail_id = this.project_info.project_setup_detail_id
            } else {
              this.detailsFormData.project_setup_detail_id = this.project_info.project_setup_detail_id
              this.project_info.project_info_details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
            }
            this.detailsFormData = {
                sub_project_name_en: '',
                sub_project_name_bn: '',
                objective_en: '',
                objective_bn: '',
                pi_designation_id: 0,
                project_id: 0,
                pi_officer_id: 0
            }
            this.editIndex = ''
        } else {
            this.$toast.error({
                title: '!',
                message: 'Item already added',
                color: '#ee5253'
            })
        }
        // reset form
        this.$nextTick(() => {
            this.$refs.form1.reset()
        })
    },
    getThematicAreaList () {
      const thematicAreaId = []
      this.thematicAreaOffice.forEach((item, key) => {
        const itThere = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(items => items.value === parseInt(item))
        thematicAreaId.push(itThere)
      })
      return thematicAreaId
    },
    async getCircularList () {
      if (this.project_info.org_id) {
          const params = {
          org_id: parseInt(this.project_info.org_id),
          office_type_id: parseInt(this.project_info.office_type_id),
          office_id: parseInt(this.project_info.office_id)
        }
        await RestApi.getData(agriResearchServiceBaseUrl, circularList, params)
        .then(response => {
          if (response.success) {
            this.circularData = response.data
          }
        })
        const circularListxx = this.circularData.map(item => {
          return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.circular_name_bn : item.circular_name, text_en: item.circular_name, text_bn: item.circular_name_bn, memo_no: item.memo_no, submission_deadline: item.submission_deadline, org_id: item.org_id, circular_type: item.circular_type })
        })
        this.circularList = circularListxx.filter(circular => circular.circular_type === 2)
        if (this.id) {
          this.getCircularMemo(this.project_info.circular_id)
        }
      }
    },
    async getProjectList () {
      if (this.project_info.circular_id) {
        const serchData = {
          circular_id: this.project_info.circular_id
        }
        const result = await RestApi.getData(agriResearchServiceBaseUrl, projectList, serchData)
        if (result.success) {
          this.thematicAreaOffice = result.thematicArray
          this.thematicAreaList = this.getThematicAreaList()
          this.projectListDropArray = result.data
          if (this.id) {
            this.getProjectListAta(this.project_info.thematic_area_id)
          }
        }
      }
    },
    getCircularMemo (orgId) {
      if (this.project_info.circular_id) {
        const memo = this.circularList.find(circular => circular.value === orgId && circular.org_id === parseInt(this.project_info.org_id))
        this.memo_no = memo.memo_no
        this.submission_deadline = memo.submission_deadline
      }
    },
    getProjectData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    finalSave () {
      this.$swal({
        title: this.$t('globalTrans.save_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.flag_status = 2
          this.saveUpdate()
        }
      })
    },
    async saveUpdate () {
      var check = await this.$refs.form.validate()
      if (check) {
        if (!this.project_info.project_info_details.length) {
          return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Add at least one item!',
            color: 'red'
          })
        }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      this.project_info.flag = this.flag_status
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${projectSetupSubUpdate}/${this.id}`, this.project_info)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, projectSetupSubStore, this.project_info)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  },
  async getOfficerList () {
      if (this.project_info.org_id > 0) {
        this.officerLoad = true
          const params = { org_id: parseInt(this.project_info.org_id) }
          const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
          if (response.success) {
              this.officerLoad = false
              this.allOfficerList = response.data
              this.officerList = this.allOfficerList.map(item => ({
                  value: item.user_id,
                  text: this.$i18n.locale === 'bn' ? item.name_bn : item.name,
                  text_en: item.name,
                  text_bn: item.name_bn,
                  designation_id: item.designation_id
              }))
          } else {
              this.officerLoad = false
              this.officerList = []
          }
        }
  }
  }
}
</script>
