<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-col md="12" class="table-responsive">
                  <table class="table">
                    <tr>
                      <th>{{ $t('org_pro.organization') }}</th>
                      <td>{{ getColumnName($store.state.commonObj.organizationProfileList, project_info.org_id) }}</td>
                      <th>{{ $t('research_manage.circular_name') }}</th>
                      <td>{{ memo_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.thematic_area_name') }}</th>
                      <td>{{ getColumnName($store.state.AgriResearch.commonObj.thematicAreaList, project_info.thematic_area_id) }}</td>
                      <th>{{ $t('research_manage.submission_deadline') }}</th>
                      <td>{{ submission_deadline | dateFormat}}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.project_name')}}</th>
                      <td> {{ currentLocale == 'bn' ? project_info.project_name_bn : project_info.project_name_en }}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </table>
              </b-col>
              <b-table-simple bordered v-if="officerList.length">
                  <thead>
                    <tr style="background-color:green;">
                      <th style="width:5%" class="text-light">{{ $t('globalTrans.sl_no') }}</th>
                      <th style="width:10%" class="text-light">{{ $t('research_manage.sub_project_title') }}</th>
                      <th style="width:15%" class="text-light">{{ $t('research_manage.objectives') }}</th>
                      <th style="width:16%" class="text-light">{{ $t('research_manage.designation') }}</th>
                      <th style="width:17%" class="text-light">{{ $t('external_research.principal_investigator') }}</th>
                      <th style="width:16%" class="text-light">{{ $t('research_manage.co_py') }} {{ $t('research_manage.designation') }}</th>
                      <th style="width:17%" class="text-light">{{ $t('research_manage.co_py') }}</th>
                    </tr>
                  </thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in project_info.project_info_details" :key="index">
                        <b-td>
                            {{ $n(index + 1) }}
                        </b-td>
                        <b-td>
                          {{ currentLocale == 'bn' ? item.sub_project_name_bn : item.sub_project_name_en }}
                        </b-td>
                        <b-td>
                          {{ currentLocale == 'bn' ? item.objective_bn : item.objective_en }}
                        </b-td>
                        <b-td>
                          {{ getDesignation(item.pi_designation_id) }}
                        </b-td>
                        <b-td>
                            {{ getOfficer(item.pi_officer_id) }}
                        </b-td>
                        <b-td>
                          {{ getMultiDesigName(item.co_pi_designation_id) }}
                        </b-td>
                        <b-td>
                          {{ getCoPyName(item.co_py) }}
                        </b-td>
                    </b-tr>
                    <b-tr v-if="project_info.project_info_details.length <= 0">
                        <td colspan="12" class="text-center">
                            <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                        </td>
                    </b-tr>
                  </b-tbody>
              </b-table-simple>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../../config/api_config'
import { projectSetupStore, projectSetupUpdate, circularList } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        options: [
          { value: 1, text: 'Coordinate Research' },
          { value: 2, text: 'Plain Research' }
        ],
        editIndex: '',
        memo_no: '',
        submission_deadline: '',
        flag_status: 1,
        project_info: {
          org_id: this.$store.state.Auth.activeRoleId !== 1 ? this.$store.state.Auth.authUser.org_id : 0,
          circular_id: 0,
          thematic_area_id: 0,
          memo_no: 0,
          submission_deadline: 0,
          project_info_details: [
          ]
        },
        detailsFormData: {
            project_name_en: '',
            project_name_bn: '',
            objective_en: '',
            objective_bn: '',
            pi_designation_id: 0,
            co_py: null,
            is_sub_project: 0,
            pi_officer_id: 0
        },
        circularList: [],
        designationList: [],
        officerList: [],
        allOfficerList: [],
        circularData: [],
        thematicAreaList: []
    }
  },
  computed: {
    yesNoList () {
      return [
        { text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
        { text: this.$i18n.locale === 'bn' ? 'না' : 'No', value: 0, text_en: 'No', text_bn: 'না' }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    },
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(project => {
            return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
          })
    },
    yearList: function () {
      return this.$store.state.commonObj.yearList
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getProjectData()
        this.project_info = tmp
    } else {
       this.project_info = Object.assign({}, this.project_info, {
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.project_info.org_id = this.$store.state.Auth.authUser.org_id
      }
    }
    this.getOfficerList()
  },
  mounted () {
    core.index()
  },
  watch: {
    'project_info.org_id': function (newValue, oldValue) {
        this.getOfficerList()
        if (newValue !== oldValue) {
            this.circularList = this.getCircularList(newValue)
            this.thematicAreaList = this.getThematicAreaList(newValue)
            this.designationList = this.getDesignationList(newValue)
        } else {
            this.circularList = []
            this.thematicAreaList = []
        }
    },
    'project_info.circular_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (!this.id) {
            this.getCircularMemo(newValue)
          }
        }
    }
  },
  methods: {
    getMultiDesigName (data) {
      const dataName = []
      data.forEach((item, key) => {
        const itThere = this.$store.state.commonObj.designationList.find(obj => obj.value === parseInt(item))
        dataName.push(this.$i18n.locale === 'bn' ? itThere.text_bn : itThere.text_en)
      })
      return dataName.toString()
    },
    getDesignationList (orgId) {
      return this.$store.state.commonObj.designationList.filter(el => el.status === 0 && el.org_id === orgId)
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getCoPyName (data) {
      const dataName = []
      data.forEach((item, key) => {
        const itThere = this.getOfficer(item)
        dataName.push(itThere)
      })
      return dataName.toString()
    },
    getDesignation (id) {
        const Obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    getOfficer (id) {
        const Obj = this.officerList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    cancelItem () {
        this.detailsFormData = {}
        this.editIndex = ''
        this.$refs.form1.reset()
    },
    editItem (item, index) {
        this.detailsFormData = Object.assign({}, item)
        this.editIndex = index
    },
    deleteItem (index) {
        this.project_info.project_info_details.splice(index, 1)
    },
    async addItem () {
      let result = []
      if (this.editIndex !== '') {
          const editList = [...this.project_info.project_info_details]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
      } else {
          result = this.project_info.project_info_details
      }
      const projectName = this.detailsFormData.project_name_en
      const newData = result.find(item => item.project_name_en === projectName)
      if (typeof newData === 'undefined') {
            if (this.editIndex !== '') {
                this.project_info.project_info_details[parseInt(this.editIndex)].project_name_en = this.detailsFormData.project_name_en
                this.project_info.project_info_details[parseInt(this.editIndex)].project_name_bn = this.detailsFormData.project_name_bn
                this.project_info.project_info_details[parseInt(this.editIndex)].objective_en = this.detailsFormData.objective_en
                this.project_info.project_info_details[parseInt(this.editIndex)].objective_bn = this.detailsFormData.objective_bn
                this.project_info.project_info_details[parseInt(this.editIndex)].pi_designation_id = this.detailsFormData.pi_designation_id
                this.project_info.project_info_details[parseInt(this.editIndex)].pi_officer_id = this.detailsFormData.pi_officer_id
                this.project_info.project_info_details[parseInt(this.editIndex)].co_py = this.detailsFormData.co_py
                this.project_info.project_info_details[parseInt(this.editIndex)].is_sub_project = this.detailsFormData.is_sub_project
            } else {
                this.project_info.project_info_details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
            }
            this.detailsFormData = {
                project_name_en: '',
                project_name_bn: '',
                objective_en: '',
                objective_bn: '',
                pi_designation_id: 0,
                is_sub_project: 0,
                pi_officer_id: 0
            }
            this.editIndex = ''
        } else {
            this.$toast.error({
                title: '!',
                message: 'Item already added',
                color: '#ee5253'
            })
        }
        // reset form
        this.$nextTick(() => {
            this.$refs.form1.reset()
        })
    },
    getThematicAreaList (orgId) {
        if (this.project_info.org_id) {
            const area = this.$store.state.AgriResearch.commonObj.thematicAreaList
            if (orgId) {
            return area.filter(item => item.org_id === parseInt(orgId))
            }
            return area
        }
    },
    async getCircularList (orgId) {
        if (this.project_info.org_id) {
          const params = {
          org_id: parseInt(this.project_info.org_id)
          // office_type_id: parseInt(this.formData.office_type_id),
          // office_id: parseInt(this.formData.office_id)
        }
        await RestApi.getData(agriResearchServiceBaseUrl, circularList, params)
        .then(response => {
          if (response.success) {
            this.circularData = response.data
          }
        })
        const circularListxx = this.circularData.map(item => {
          return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.circular_name_bn : item.circular_name, text_en: item.circular_name, text_bn: item.circular_name_bn, memo_no: item.memo_no, submission_deadline: item.submission_deadline, org_id: item.org_id, circular_type: item.circular_type })
        })
        this.circularList = circularListxx.filter(circular => circular.circular_type === 2)
        if (this.id) {
          this.getCircularMemo(this.project_info.circular_id)
        }
      }
    },
    getCircularMemo (orgId) {
        if (this.project_info.circular_id) {
          const memo = this.circularList.find(circular => circular.value === orgId && circular.org_id === parseInt(this.project_info.org_id))
          this.memo_no = memo.memo_no
          this.submission_deadline = memo.submission_deadline
        }
    },
    getProjectData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    finalSave () {
      this.$swal({
        title: this.$t('globalTrans.save_msg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.flag_status = 2
          this.saveUpdate()
        }
      })
    },
    async saveUpdate () {
      var check = await this.$refs.form.validate()
      if (check) {
        if (!this.project_info.project_info_details.length) {
          return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Add at least one item!',
            color: 'red'
          })
        }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      this.project_info.flag = this.flag_status
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${projectSetupUpdate}/${this.id}`, this.project_info)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, projectSetupStore, this.project_info)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  },
  async getOfficerList () {
      if (this.project_info.org_id > 0) {
        this.officerLoad = true
          const params = { org_id: parseInt(this.project_info.org_id) }
          const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
          if (response.success) {
              this.officerLoad = false
              this.allOfficerList = response.data
              this.officerList = this.allOfficerList.map(item => ({
                  value: item.user_id,
                  text: this.$i18n.locale === 'bn' ? item.name_bn : item.name,
                  text_en: item.name,
                  text_bn: item.name_bn,
                  designation_id: item.designation_id
              }))
          } else {
              this.officerLoad = false
              this.officerList = []
          }
        }
  }
  }
}
</script>
